// font
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat-v15-latin-regular.ttf');
}

@font-face{
    font-family: 'Khmer OS Battambang';
    src: url('../fonts/KhmerOSbattambang.ttf');
}

@font-face{
    font-family: 'Khmer OS Muol';
    src: url('../fonts/KhmerOSmuol.ttf');
}

@font-face{
    font-family: 'Khmer OS Muol Light';
    src: url('../fonts/KhmerOSmuollight.ttf');
}

#root {
    height: 100%;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.position-absolute {
    position: absolute;
}

// ScrollBar
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $gray-500; 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $gray-400; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $gray-600; 
}

// Margin
@for $i from 0 through 100 {
    .m-#{$i} {
        margin: #{$i}px !important;
    }
}
  
@for $i from 0 through 100 {
    .mt-#{$i} {
        margin-top: #{$i}px !important;
    }
}
  
@for $i from 0 through 100 {
    .mb-#{$i} {
        margin-bottom: #{$i}px !important;
    }
}
  
@for $i from 0 through 100 {
    .ml-#{$i} {
        margin-left: #{$i}px !important;
    }
}
  
@for $i from 0 through 100 {
    .mr-#{$i} {
        margin-right: #{$i}px !important;
    }
}

// Padding
@for $i from 0 through 100 {
    .p-#{$i} {
        padding: #{$i}px !important;
    }
}

@for $i from 0 through 100 {
    .pt-#{$i} {
        padding-top: #{$i}px !important;
    }
}

@for $i from 0 through 100 {
    .pb-#{$i} {
        padding-bottom: #{$i}px !important;
    }
}

@for $i from 0 through 100 {
    .pl-#{$i} {
        padding-left: #{$i}px !important;
    }
}

@for $i from 0 through 100 {
    .pr-#{$i} {
        padding-right: #{$i}px !important;
    }
}

// Font Size
@for $i from 0 through 100 {
    .fs-#{$i} {
        font-size: #{$i}px !important;
    }
}

.complex-filter {
    border: 1px solid $primary-color !important;
    padding: 20px !important;
    background-color: $gray-100 !important;
    min-width: 300px !important;
    max-width: 400px !important;
    // position: fixed !important;
}

.font-bold-900 {
    font-weight: 900 !important;

    th {
        font-weight: 900 !important;
    }
}
.font-bold-750 {
    font-weight: 600 !important;

    th {
        font-weight: 600 !important;
    }
}